// Libs
import urlSlug from "url-slug";

// Utils
import { Konkurs } from "../../models/Konkurs";
import { formatDate } from "../../util";

import styles from "./PublicJobCard.module.scss";

export interface PublicJobCardProps {
  konkurs: Konkurs;
}

export const PublicJobCard = ({ konkurs }: PublicJobCardProps) => {
  const path: string = "/posao/" + konkurs.id + "/" + urlSlug(konkurs.naslov);

  return (
    <a className={styles.container} href={path}>
      <div className={styles.title}>
        <h2>{konkurs.naslov}</h2>
      </div>
      <div className={styles.info}>
        <div className={styles.wrapper}>
          <p className={styles.subtitle}>Lokacija</p>
          <p className={styles.data}>{konkurs.lokacija}</p>
        </div>
        <div className={styles.wrapper}>
          <p className={styles.subtitle}>Oglas važi do</p>
          <p className={styles.data}>{formatDate(konkurs.rokPrijava)}.</p>
        </div>
      </div>
    </a>
  );
};
