// Libs
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import urlSlug from "url-slug";
import moment from "moment";
import "moment/locale/bs"; // without this line it didn't work

// Components
import { Button } from "../Button/Button";
import { PremiumTag } from "./PremiumTag";

// Utils
import { formatDate } from "../../util";
import { Konkurs } from "../../models/Konkurs";
import styles from "./JobCard.module.scss";

// Assets
import defaultLogo from "../../assets/logos/logo.png";

export interface JobCardProps {
  konkurs: Konkurs;
  className?: string;
  buttonStyle?: string;
  tag?: boolean;
}

export const JobCard: React.FunctionComponent<JobCardProps> = ({
  konkurs,
  className = "",
  buttonStyle = "",
  tag = false,
}) => {
  const history = useHistory();
  moment.locale("bs");
  const [expired, setExpired] = useState(false);

  const [tagText, setTagText] = useState("");
  const [tagColor, setTagColor] = useState("var(--red)");

  const isPremium = useMemo(() => {
    if (konkurs.premium && konkurs.premiumDo) {
      const now = moment(new Date());
      const date = moment(new Date(konkurs.premiumDo));

      return konkurs.premium && date.isAfter(now);
    }
    return false;
  }, []);

  const showTag = tag || (konkurs.izdvojeniOglas && !konkurs.premium);

  const path: string = "/posao/" + konkurs.id + "/" + urlSlug(konkurs.naslov);

  const onClick = () => {
    const path = urlSlug(konkurs.naslov);
    history.push("/posao/" + konkurs.id + "/" + path);
  };

  useEffect(() => {
    const now = moment(new Date());
    const date = moment(new Date(konkurs.rokPrijava));

    const hasExpired = date.isBefore(now);

    setExpired(hasExpired);

    if (hasExpired) {
      setTagText("Oglas je istekao");
      setTagColor("var(--red)");
    } else if (konkurs.izdvojeniOglas) {
      setTagText("Izdvojeno");
      setTagColor("var(--green)");
    }
  }, [expired, konkurs]);

  return (
    <a
      href={path}
      className={classNames(
        styles.container,
        className,
        expired && tag ? styles.expired : "",
        konkurs.izdvojeniOglas ? styles.izdvojeni : "",
        isPremium ? styles.premium : ""
      )}
    >
      {isPremium && <PremiumTag />}

      {showTag && tagText && (
        <div
          className={styles.tag}
          style={{
            backgroundColor: tagColor,
          }}
        >
          <p>{tagText}</p>
        </div>
      )}

      <a
        className={styles.link}
        href={konkurs.urlPravnogLica ?? "/"}
        target="_blank"
        rel="noreferrer"
      >
        <div
          className={styles.logo}
          style={{
            backgroundImage: konkurs?.slika
              ? `url(${konkurs.slika})`
              : `${defaultLogo}`,
          }}
        ></div>
      </a>

      <div className={styles.content} onClick={onClick}>
        <h2 className={styles.title}>{konkurs.naslov ?? ""}</h2>
        <div className={styles.info}>
          <div className={styles.location}>
            <div className={styles.icon} /> Lokacija:
            <span className={styles.span}> {konkurs?.lokacija ?? ""}</span>
          </div>
          <div className={styles.dueDate}>
            <div className={styles.icon} /> Rok za prijavu:
            <span className={styles.span}>
              {formatDate(konkurs.rokPrijava)}.
            </span>
          </div>
        </div>
      </div>

      <div className={classNames(styles.action, buttonStyle)}>
        <Button type="link" className={styles.button} onClick={onClick}>
          Detalji konkursa
        </Button>
      </div>
    </a>
  );
};
