import { Blog } from "../../models/Blog";
import styles from "./PremiumArticleCard.module.scss";
import urlSlug from "url-slug";
import { ReactComponent as PremiumIcon } from "../../assets/icons/premium.svg";

interface PremiumArticleCardProps {
  blog: Blog;
}

export const PremiumArticleCard: React.FunctionComponent<
  PremiumArticleCardProps
> = ({ blog }) => {
  const path: string = "/objava/" + blog.id + "/" + urlSlug(blog.naslov);

  return (
    <a className={styles.premiumArticleCard} href={path}>
      <div className={styles.tag}>
        <PremiumIcon />
        <p className={styles.tag__text}>premium</p>
      </div>

      <h2 className={styles.title}>{blog.naslov ?? ""}</h2>
    </a>
  );
};
