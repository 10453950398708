import urlSlug from "url-slug";
import { useHistory } from "react-router-dom";

import { Konkurs } from "../../models/Konkurs";
import styles from "./PremiumJobCard.module.scss";

// Assets
import defaultLogo from "../../assets/logos/logo.png";
import { ReactComponent as PremiumIcon } from "../../assets/icons/premium.svg";

interface PremiumJobCardProps {
  konkurs: Konkurs;
}

export const PremiumJobCard: React.FunctionComponent<PremiumJobCardProps> = ({
  konkurs,
}) => {
  const history = useHistory();

  const path: string = "/posao/" + konkurs.id + "/" + urlSlug(konkurs.naslov);

  const onClick = () => {
    const path = urlSlug(konkurs.naslov);
    history.push("/posao/" + konkurs.id + "/" + path);
  };

  return (
    <a className={styles.premiumJobCard} href={path}>
      <div className={styles.tag}>
        <PremiumIcon />
        <p className={styles.tag__text}>premium</p>
      </div>

      <a
        className={styles.link}
        href={konkurs.urlPravnogLica ?? "/"}
        target="_blank"
        rel="noreferrer"
      >
        <div
          className={styles.logo}
          style={{
            backgroundImage: konkurs?.slika
              ? `url(${konkurs.slika})`
              : `${defaultLogo}`,
          }}
        />
      </a>
      <h2 className={styles.title}>{konkurs.naslov ?? ""}</h2>
      <div className={styles.location}>
        <div className={styles.icon} /> Lokacija:
        <span className={styles.span}> {konkurs?.lokacija ?? ""}</span>
      </div>
    </a>
  );
};
